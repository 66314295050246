<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="query"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="sourcePort">
          <el-select
              v-model="query.sourcePort"
              clearable
              placeholder="请选择-来源端"
              style="width: 260px"
          >
            <el-option label="IOS" value="1"></el-option>
            <el-option label="android" value="2"></el-option>
            <el-option label="巡检充值" value="3"></el-option>
            <el-option label="其他" value="4"></el-option>
            <el-option label="app端的H5" value="5"></el-option>
            <el-option label="商户端" value="6"></el-option>
            <el-option label="用户端" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="paymentChannel">
          <el-select
              v-model="query.paymentChannel"
              clearable
              placeholder="请选择-支付渠道"
              style="width: 260px"
          >
            <el-option label="支付宝" value="0"></el-option>
            <el-option label="微信" value="1"></el-option>
            <el-option label="农行" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="rechargeObject">
          <el-select
              v-model="query.rechargeObject"
              clearable
              placeholder="请选择-充值对象"
              style="width: 260px"
          >
            <!--普通注册用户、城市管理平台巡检用户、城市管理平台用户、慧停车管理平台用户、停车场管理平台用户、结算方、稽查端、关联方、商户端、岗亭端、用户端、机关单位巡检-->
            <el-option label="普通注册用户" value="0"></el-option>
            <el-option label="城市管理平台巡检用户" value="1"></el-option>
            <el-option label="城市管理平台用户" value="2"></el-option>
            <el-option label="慧停车管理平台用户" value="3"></el-option>
            <el-option label="停车场管理平台用户" value="4"></el-option>
            <el-option label="结算方" value="5"></el-option>
            <el-option label="稽查端" value="6"></el-option>
            <el-option label="关联方" value="7"></el-option>
            <el-option label="商户端" value="9"></el-option>
            <el-option label="岗亭端" value="10"></el-option>
            <el-option label="用户端" value="11"></el-option>
            <el-option label="机关单位巡检" value="13"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="manualOperation">
          <el-select
              v-model="query.manualOperation"
              clearable
              placeholder="请选择-人工操作"
              style="width: 260px"
          >
            <el-option label="无" value="0"></el-option>
            <el-option label="人工冲正" value="-1"></el-option>
            <el-option label="人工冲红" value="-2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="plateId">
          <!-- <el-input
            v-model="query.plateNumber"
            placeholder="搜索车牌号码"
            style="width: 260px"
          ></el-input> -->
          <NumplateSuggest v-model="query.plateId" style="width: 260px"/>
        </el-form-item>
        <el-form-item prop="userTel">
          <el-input
              v-model="query.userTel"
              clearable
              placeholder="搜索手机号码"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="thirdNo">
          <el-input
              v-model="query.thirdNo"
              clearable
              placeholder="平台流水号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="outThirdNo">
          <el-input
              v-model="query.outThirdNo"
              clearable
              placeholder="第三方流水号"
              prefix-icon=""
              style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="searchDate"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
          <span style="margin-left: 30px"
          >合计金额<span class="total-size"
          >{{ parseFloat(totalMoney).toFixed(2) }} </span
          >元</span
          >
        </div>
        <div class="op-extra">
          <icon-button
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              align="center"
              label="用户手机号码"
              prop="userTel"
              width="240"
          >
          </el-table-column>
          <el-table-column align="center" label="车牌号码" width="160">
            <template slot-scope="scope">
              <Numplate :type="scope.row.plateColor">{{
                  scope.row.plateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="金额（元）"
              prop="amount"
              width="100"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.amount).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="支付渠道" width="160">
            <template slot-scope="scope">
              <span>{{
                  paymentChannel[scope.row.paymentChannel.toString()]
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="来源端" width="160">
            <template slot-scope="scope">
              <span>{{
                  scope.row.sourcePort === 1
                      ? "IOS"
                      : scope.row.sourcePort === 2
                          ? "android"
                          : scope.row.sourcePort === 3
                              ? "巡检充值"
                              : scope.row.sourcePort === 4
                                  ? "其他"
                                  : scope.row.sourcePort === 5
                                      ? "app端的H5"
                                      : scope.row.sourcePort === 6
                                          ? "商户端"
                                          : "用户端"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="充值对象" width="200">
            <template slot-scope="scope">
              <span>{{
                  scope.row.rechargeObject === -1
                      ? ""
                      : rechargeObject[scope.row.rechargeObject]
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="城区" prop="regionName" width="160">
          </el-table-column>
          <el-table-column
              align="center"
              label="到账时间"
              prop="arrivalTime"
              width="180"
          >
          </el-table-column>
          <el-table-column label="平台流水号" prop="thirdNo" width="300">
          </el-table-column>
          <el-table-column label="第三方流水号" prop="outThirdNo" width="200">
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import Numplate from "@/components/Numplate";
import {searchRecharge} from "@/api/finance";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";

import NumplateSuggest from "@/components/NumplateSuggest";
import {doExport} from "@/api/common";

export default {
  name: "recharge-search",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      searchDate: [],
      dataList: [],
      totalMoney: 0,
      exportDialogFormVisible: false,
      form: {
        exportSize: 1,
      },
      query: {
        sourcePort: "",
        paymentChannel: "",
        rechargeObject: "",
        manualOperation: "",
        plateId: "",
        userTel: "",
        thirdNo: "",
        outThirdNo: "",
        startDate: "",
        endDate: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
        regionId: "530129",
      },
      rechargeObject: {
        //充值对象（0普通注册用户，1城市管理平台巡检用户，2城市管理平台用户，3慧停车管理平台用户 ，4停车场管理平台用户 ，5结算方 ，6稽查端，7关联方，9商户端，10岗亭端，11用户端，13机关单位巡检）
        0: "普通注册用户",
        1: "城市管理平台巡检用户",
        2: "城市管理平台用户",
        3: "慧停车管理平台用户",
        4: "停车场管理平台用户",
        5: "结算方",
        6: "稽查端",
        7: "关联方",
        9: "商户端",
        10: "岗亭端",
        11: "用户端",
        13: "机关单位巡检",
      },
      paymentChannel: {
        "-1": "",
        0: "支付宝",
        1: "微信",
        2: "农行",
      },
      paymentMethod: {
        "-1": "",
        0: "支付宝",
        1: "微信",
        2: "农行",
      },
    };
  },
  components: {
    Numplate,
    NumplateSuggest,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.searchDate = [];
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      this.isLoading = true;
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const params = this.paramFormat(this.query);
      if (this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      const res = await searchRecharge(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.form.exportSize = returnObject.total;
        this.totalMoney = returnObject.totalAmount;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.searchDate.length > 0) {
        params["startDate"] = this.searchDate[0];
        params["endDate"] = this.searchDate[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport("/MoneyManagement/searchRecharge", params, "充值查询.xlsx");
      // window.open("http://172.16.69.129:81" + "/MoneyManagement/searchRecharge?" + objectToQueryString(params));
      loading.close();
      this.exportDialogFormVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
